<nav id="first-nav" class="navbar navbar-default">
  <div class="container">
    <div class="navbar-header">
      <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar">
        <span class="sr-only">Toggle navigation</span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
      </button>
      <a [routerLink]="['/']" class="navbar-brand">
        <img src="assets/img/logo_BH-1-170.png" alt="Logo">
      </a>
    </div>
    <div id="navbar" class="navbar-collapse collapse">
      <ul class="nav navbar-nav text-uppercase">
        <li><a [routerLink]="" (click)="scrollTo('features')">Features</a></li>
        <li><a [routerLink]="" (click)="scrollTo('howitworks')">How it works</a></li>
      </ul>
    </div>
  </div>
</nav>
<div class="blue-bar"></div>
<nav id="second-nav" class="navbar navbar-default">
  <div class="container">
    <div id="secondnavbar">
      <ul class="nav navbar-nav">
        <li><a [routerLink]="" (click)="scrollTo('carecoach')">Care Coach</a></li>
        <li><a [routerLink]="" (click)="scrollTo('safekeeper')">SafeKeeper</a></li>
        <li><a [routerLink]="" (click)="scrollTo('familycalendar')">Family Calendar</a></li>
        <li><a [routerLink]="" (click)="scrollTo('messaging')">Messaging</a></li>
        <li><a [routerLink]="" (click)="scrollTo('picsharing')">Picture &amp; Video Sharing</a></li>
        <li><a [routerLink]="" (click)="scrollTo('seniortablet')">Senior Tablet App</a></li>
        <li><a [routerLink]="" (click)="scrollTo('permissions')">Permissions</a></li>
      </ul>
    </div>
  </div>
</nav>