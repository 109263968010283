<section class="hills">
  <svg viewBox="0 0 1600 194" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 8v182h1151C843.9 97.4 445.5 32.3 0 8z" fill="#F0BD1B" />
    <path d="M1600 3v191H0v-4C411.5 72.6 969.2 4.7 1600 3z" fill="#1A475F" />
  </svg>
</section>
<footer class="footer">
  <div class="container">
    <div class="copyright">
      <div>
        <a href="https://www.elivelihood.com" target="_blank">eLivelihood Inc. 2023</a>
      </div>
      <div class="brands">
        <div class="brand">
          <img src="assets/img/iso-certified.png" />
        </div>
        <div class="brand">
          <img src="assets/img/soc-new-logo-blue.png" />
        </div>
        <div class="brand">
          <img src="assets/img/hipaa-compliant-white.png" />
        </div>
        <div class="brand">
          <img src="assets/img/gdpr-certified-new.png" />
        </div>
      </div>
    </div>
  </div>
</footer>
